const en = {
    translation: {
        common: {
            errors: {
                page_not_found: {
                    title: '404 - Page Not Found',
                    text: 'Sorry, the page you are attempting to access<br/> could not be located.'
                },
                network: {
                    title: 'Connection lost',
                    text: 'Please, try to reconnect.'
                },
                unauthorized: {
                    title: 'No access',
                    text: 'You are not authorized to view this page.'
                },
                maintenance: {
                    title: 'Page under maintenance',
                    text: "We're busy updating the Network App Marketplace for you.<br/> Meanwhile, please subscribe to our <0>Status Page</0> for updates<br/> on ongoing and future maintenance."
                },
                no_results: {
                    title: 'No results found',
                    text: "Sorry, we couldn't find what you're looking for.<br/> Try another way"
                },
                unknown: {
                    title: 'An unknown error occurred',
                    text: 'Please, try again.'
                }
            },
            warnings: {
                select_account: 'You are currently viewing all accounts.\
                Please select a specific account at the upper right corner and then proceed with the action.'
            },
            message: {
                retry: 'Please try again',
                ok: 'Ok'
            },
            top_bar: {
                search_placeholder: 'Type to search',
                account: {
                    title: 'Account',
                    all_accounts: 'All accounts',
                    select_all: 'Select all accounts',
                    not_found: 'No accounts found'
                }
            },
            components: {
                file_upload: {
                    title: 'Uploaded files',
                    title_single: 'Uploaded file'
                },
                charts: {
                    area_chart: {
                        no_consumption: 'No consumption',
                        not_activated: 'Your SIM has not been activated yet.'
                    }
                }
            }
        },
        header: {
            nav_menu: {
                dashboard: 'Dashboard',
                onechip: 'OneChip eUICC',
                networkapps: 'Network Apps',
                add_ons: 'Add-Ons'
            },
            cart: {
                title: 'Online order',
                total: 'Total: {{price, currency(USD)}}',
                item_price: '{{price, currency(USD)}}',
                checkout: 'Checkout',
                no_items: 'No items'
            },
            profile: {
                my_user: 'My user',
                support: 'Support',
                log_out: 'Log out'
            }
        },
        footer: {
            tcs: 'T&Cs',
            privacy_policy: 'Privacy Policy',
            version: 'Version {{version}}'
        },
        views: {
            login: {
                title: 'Log In',
                no_account: 'Don`t have an account?',
                contact_us: 'Contact us',
                forgot_password: 'Forgot password?',
                email: 'Email',
                password: 'Password',
                login: 'Log in'
            },
            password_forgot: {
                title: 'Forgot password?',
                description:
                    "Please enter the email associated with your user account and we'll email you instructions on how to reset your password.",
                email: 'Email',
                reset_password: 'Reset password',
                go_back: 'Back to Log In',
                success: {
                    title: 'Request submitted',
                    text: 'We have sent you an email to reset your password. Make sure to check your spam folder.'
                }
            },
            password_reset: {
                title: 'Enter your new password',
                description: 'Make sure your new password is at least 5 characters long.',
                password: 'Password',
                confirm_password: 'Repeat password',
                save_password: 'Save password'
            },
            dashboard: {
                news: {
                    title: 'What`s new?',
                    buttons: {
                        close: 'Close'
                    }
                },
                charts: {
                    active_apps: {
                        description: 'Network apps in use',
                        info: 'This is the amount of unique network apps currently active on your eSIMs, out of all available network apps in your marketplace.'
                    },
                    average_usage_per_esim: {
                        count: '{{val, number}} {{measureUnit}}',
                        description: 'Average usage per eSIM',
                        info: 'This is the average usage for an eSIM, that has been consuming data this calendar month.'
                    },
                    total_esims: {
                        description: 'Total number of eSIMs',
                        info: 'This is the total number of eSIMs on your account.'
                    },
                    data_chart: {
                        data_consumption: {
                            title: 'Data Consumption',
                            card: {
                                description: 'Data volume last {{value}} days',
                                info: 'Deviation from the previous {{value}} days'
                            }
                        },
                        data_usage: {
                            title: 'Data Usage Map'
                        },
                        filters: {
                            month: 'Last 30 days',
                            week: 'Last 7 days'
                        }
                    },
                    network_status: {
                        title: 'Device Network Status',
                        legends: {
                            activating: 'Activating',
                            online: 'Online',
                            waiting: 'Activated',
                            stopped: 'Suspended',
                            deactivated: 'Deactivated',
                            unactivated: 'Unactivated'
                        }
                    },
                    operation_status: {
                        title: 'eSIM Operation Status',
                        legends: {
                            activated: 'Success',
                            failed: 'Failed',
                            queued: 'Queued',
                            canceled: 'Canceled',
                            installed: 'Installing'
                        }
                    },
                    total_data_consumption: {
                        title: 'Total Data Consumption',
                        widget_text: 'Total Consumption'
                    },
                    top_metrics: {
                        title: 'Top Consumption Metrics',
                        onechip: {
                            title: 'OneChip',
                            table_headers: {
                                no: 'No',
                                onechip: 'Onechip',
                                usage: 'Usage (MB)'
                            }
                        },
                        network_app: {
                            title: 'Network apps',
                            table_headers: {
                                no: 'No',
                                app: 'Network app',
                                usage: 'Usage (MB)'
                            }
                        }
                    }
                }
            },
            network_apps: {
                sorting: {
                    label: 'Sort by',
                    order: {
                        name_asc: 'Name (ASC)',
                        name_desc: 'Name (DESC)',
                        price_asc: 'Price low to high',
                        price_desc: 'Price high to low'
                    }
                },
                csv_upload: {
                    success: 'App install is in progress',
                    failure: 'An error occurred',
                    actions: {
                        continue: 'Continue',
                        cancel: 'Cancel'
                    },
                    placeholder: 'Drag & drop or select your CSV files here',
                    description: 'Select a CSV file with header “eid” followed by eIDs listed in one column. \
                    All eIDs defined in the file will be activated.',
                    download_example: 'Download example file'
                }
            },
            add_ons: {
                cards: {
                    meerkat: {
                        title: 'Meerkat Private Gateway',
                        description: 'Meerkat is a virtual private gateway that can be used to intelligently route data between devices and public cloud providers. \
                        Automatically connect to leading cloud services without storing SDKs or \
                        credentials on devices. Upscale, encrypt, and secure data traffic without draining battery life or adding hardware costs. \
                        Teal\'s Meerkat Cloud Integration solution currently supports: \
                        <ul> \
                        <li>AWS loT Core</li> \
                        <li>Amazon Kinesis</li> \
                        <li>Amazon Kinezis Firehose</li> \
                        </ul>',
                        products: [
                            {
                                title: 'Meerkat Public Static IP',
                                description: 'Meerkat can provision publicly reachable static IPv4 and IPv6 endpoints that correlate with the carrier addresses and stay with your device as it changes networks.',
                                details: 'per IPv4/IPv6 address',
                                price_details: '{{price, currency(USD)}} / month',
                                confirmation: {
                                    title: 'Meerkat Public Static IP \
                                    $2.00/month per IPv4/IPv6 address',
                                    description: 'You are about to order the Meerkat Public Static IP service that enables you to assign public static IPs to your endpoints.<br /> \
                                    Public IPs can be assigned on the OneChip eUICC page and on the Meerkat tab within OneChip details.<br /> \
                                    Any active public IP will be invoiced at the end of the monthly billing cycle.'
                                }
                            },
                            {
                                title: 'Meerkat VPN Gateway',
                                description: 'IPsec tunnels are pre-configured between Teal carrier partners and our Meerkat Gateway. \
                                A VPN is defined between Meerkat Gateway and your application.<br/> \
                                Our VPN tunnel routes all of your application data direct from the carriers’s PGW to your internal application.<br/> The direct-to-doorstep functionality is easy as typing the “meerkat.teal” APN in your device settings, and is ideal for sensitive government, health, or financial data.',
                                details: 'Setup fee',
                                price_details: '{{price, currency(USD)}}',
                                confirmation: {
                                    title: 'Meerkat VPN Gateway \
                                    $2.00/month per IPv4/IPv6 address',
                                    description: 'The one-time setup fee includes VPN configuration and support.<br /> \
                                    Private IPv4 can be assigned on the OneChip eUICC page and on the Meerkat tab for the OneChip card, at no additional per-device cost.'
                                }
                            }
                        ],
                        successPage: {
                            description: 'Thank you for your order!<br /> All your active public IPs will be invoiced at the end of the monthly billing cycle.  <br /><br /> Start assigning static IP address to your devices in Meerkat  <Link>here</Link>'
                        },
                        actions: {
                            manage: 'Manage',
                            order: 'Order'
                        }
                    },
                    cloud: {
                        title: 'Teal CloudKey for AWS',
                        description: 'Automate your device provisioning and activation within AWS. Automatically convert CoAP, CoAPS, HTTP, HTTPS and UDS traffic.',
                        products: []
                    }
                },
            },
            support: {
                title: 'Support',
                cards: {
                    quick_start: 'Quickstart Guide',
                    user_guide: 'User Guide',
                    faq: 'FAQ',
                    troubleshooting: 'Troubleshooting Guide',
                    report_problem: 'Report a Problem'
                },
                actions: {
                    open: 'Open',
                    report: 'Report'
                }
            },
            user: {
                my_user: {
                    title: 'My user',
                    header: 'User Info',
                    user_info_data: {
                        address_one: 'Address 1',
                        address_two: 'Address 2',
                        state_region: 'State/Region',
                        city_town: 'Town/City',
                        postcode_zip: 'Zip/Postal code',
                        country: 'Country',
                    },
                    change_password: {
                        title: 'Change password',
                        message: {
                            password_updated_successfully: 'Password updated successfully',
                            update_password_failure: 'Failed to update password. Please try again',
                        },
                        actions: {
                            cancel: 'Cancel',
                            save: 'Save',
                        },
                        fields: {
                            current_password: {
                                label: 'Current password',
                            },
                            new_password: {
                                label: 'New password',
                            },
                            repeat_password: {
                                label: 'Repeat new password',
                                validation: {
                                    match: 'Passwords must match'
                                }
                            }
                        }
                    },
                    credit_card_form: {
                        description_start: 'Select payment method to use for automated invoice payments. Your next invoices will be charged with the chosen payment method.',
                        description_end: 'If you would like to use this payment method to close out an outstanding invoice, please contact',
                        invoice_payment_methods: 'Invoice payment methods',
                        add_new_cart: '+ Add new card',
                        payment_methods: 'Payment methods',
                        none: 'None'
                    },
                    payment_methods: {
                        account_not_selected_first_row: 'You are currently viewing all accounts.',
                        account_not_selected_second_row: 'Please select a specific account at the upper right corner to view or add payment methods.',
                        successful_adding: 'New Payment Method added',
                        adding_failure: 'Card was not added. Please retry.',
                        title: 'Add payment method',
                        card_number: 'Card number',
                        expiration_date: 'MM/YY',
                        cvc: 'CVC',
                        name_on_cart: 'Name on card',
                        cancel: 'Cancel',
                        add: 'Add',
                        error: 'Error!',
                        try_again: 'Try again',
                        card_not_added_error: 'Card has not been added. Please try again!'
                    },
                    image_upload: {
                        title: 'Upload photo',
                        upload_profile: 'Upload profile',
                        upload_drag_message: 'Drag to reposition photo.',
                        remove: 'Remove',
                        select_image: 'Select image',
                        save: 'Save',
                        success: 'Image was uploaded successfully',
                        failure: 'Image upload failure',
                    },
                    image_remove: {
                        success: 'Image was successfully removed',
                        failure: 'Image remove failure',
                    },
                    delete_payment: {
                        delete_card_modal_header: 'Delete payment method?',
                        delete_card_modal_request: 'Are you sure you want to delete this card?',
                        success: 'Payment Method deleted',
                        failure: 'Payment Method delete failure',
                        cancel: 'Cancel',
                        delete: 'Delete',
                    },
                },
                api: {
                    title: 'API',
                    sections: {
                        api_access: {
                            title: 'Api access',
                            labels: {
                                api_key: 'API Key',
                                api_secret: 'API Secret'
                            }
                        },
                        notification_config: {
                            title: 'Notification Config',
                            labels: {
                                notification_queue_url: 'Notification Queue URL',
                                api_notification_queue_url: 'Integration API Operation Result Notification URL',
                                notification_endpoint: 'Custom System Notification Endpoint',
                                aws_access_key_id: 'AWS Access Key ID',
                                aws_secret_access_key: 'AWS Secret Access Key',
                            }
                        }
                    }
                },
                invoice_history: {
                    title: 'Invoice history',
                    table_headers: {
                        invoice_no: 'Invoice no.',
                        account: 'Account',
                        period: 'Period',
                        one_chips: 'OneChip qty',
                        approved: 'Approved',
                        due_date: 'Due date',
                        total: 'Total $',
                    },
                    month: {
                        options: [
                            "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                        ]
                    },
                },
            },
            store: {
                onechip_store: {
                    title: 'OneChip Store',
                    main: {
                        legends: {
                            card_design: 'Card Design',
                            artwork: 'Artwork'
                        },
                        actions: {
                            add_to_cart: 'Add to Cart'
                        }
                    },
                    checkout: {
                        sections: {
                            billing_address: {
                                labels: {
                                    country: 'Country',
                                    state: 'State',
                                    city: 'City',
                                    address: 'Address',
                                    address_extended: 'Address2',
                                    postal_code: 'Postcode/ZIP',
                                    email: 'Email'
                                }
                            },
                            general_info: {
                                title: 'General Info',
                                fields: {
                                    first_name: {
                                        label: 'First name'
                                    },
                                    last_name: {
                                        label: 'Last name'
                                    },
                                    email: {
                                        label: 'Email'
                                    },
                                    company: {
                                        label: 'Company'
                                    }
                                }
                            },
                            shipping_address: {
                                title: 'Shipping Info',
                                fields: {
                                    country: {
                                        label: 'Country'
                                    },
                                    state: {
                                        label: 'State'
                                    },
                                    city: {
                                        label: 'City'
                                    },
                                    address: {
                                        label: 'Address'
                                    },
                                    zip: {
                                        label: 'Zip'
                                    },
                                    phone: {
                                        label: 'Phone'
                                    },
                                    address2: {
                                        label: 'Address2'
                                    },
                                    vat: {
                                        label: 'VAT/Tax ID (for international shipping)'
                                    },
                                    note: {
                                        label: 'Delivery note'
                                    },
                                }
                            },
                            order_summarry: {
                                title: 'Order summarry',
                                table: {
                                    headers: {
                                        item: 'ITEM',
                                        price: 'PRICE',
                                        quantity: 'QTY',
                                        total: 'TOTAL'
                                    }
                                },
                                details: {
                                    subtotal: 'Subtotal',
                                    shipping: 'Shipping',
                                    estimated_tax: 'Estimated tax',
                                    grand_total: 'GRAND TOTAL',
                                    shipping_special_details: '*Shipping is free of charge for domestic orders of greater than 100 units within the US. Shipping costs for international orders and domestic orders of less than 100 units will be billed separately.',
                                    payment_methods: 'Payment methods',
                                    save_payment_methods: 'Save my payment method',
                                    actions: {
                                        checkout: 'Checkout'
                                    }
                                }
                            }
                        },
                        errorPage: {
                            title: 'Error',
                            text: 'Unfortunately we run into problem and couldn’t place your order. Please try again or contact teal support:',
                            actions: {
                                cancel: 'Cancel',
                                back: 'Back to checkout'
                            }
                        },
                        successPage: {
                            title: 'Order placed',
                            text: 'Thank you for your order. You will be informed about the order progress via email.',
                            actions: {
                                ok: 'Ok'
                            },
                            order_details: {
                                labels: {
                                    order_no: 'Order number',
                                    timestamp: 'Date',
                                    payment_method: 'Payment method',
                                    transaction_id: 'Transaction ID',
                                },
                                receipt: ' Your receipt is <Link>here</Link>',
                                payment: 'You can review your payments on "Payment" page: <Link>Your Payments</Link>',
                                order: 'You can track your order status: <Link>Your Order</Link>'
                            }
                        }
                    }
                },
                order_history: {
                    title: 'Order history',
                    row_content_headers: {
                        shipping_details: 'Shipping details',
                        order_summary: 'Order summary',
                    },
                    row_content_key_names: {
                        esimInfo: {
                            artwork: 'Artwork ',
                            card_design: 'Card design ',
                            connectivity: 'Connectivity ',
                            sku: 'SKU ',
                            quantity: 'Quantity ',
                        },
                        shipping_details: {
                            first_name: 'First name ',
                            last_name: 'Last name ',
                            email: 'Email ',
                            company: 'Company ',
                            country: 'Country ',
                            state: 'State ',
                            city: 'City ',
                            address: 'Address ',
                            zip: 'ZIP ',
                            phone: 'Phone ',
                            address2: 'Address 2 ',
                        },
                        order_summary: {
                            order_number: 'Order number ',
                            status: 'Status ',
                            transaction_id: 'Transaction ID ',
                            transaction_url: 'Tracking URL ',
                            delivery_note: 'Delivery note'
                        },
                    },
                    table_headers: {
                        account: 'Account',
                        order_number: 'Order number',
                        product: 'Product',
                        status: 'Status',
                        date: 'Date',
                        total_price: 'Total price',
                        total: 'Total',
                        show_receipt: 'Show receipt'
                    },
                    filters: {
                        title: 'Filter',
                        title_filters: 'Filters',
                        clear_all: 'Clear all',
                        apply: 'Apply',
                        type_to_search: 'Type to search...',
                        sections_titles: {
                            order_number: 'Order number',
                            product: 'Product',
                            status: 'Status',
                        },
                    },
                    total: '{{price, currency(USD)}}'
                },
                payment_history: {
                    title: 'Payment History',
                    table_headers: {
                        account: 'Account',
                        transaction_id: 'Transaction ID',
                        product: 'Product',
                        transaction_status: 'Status',
                        date: 'Date',
                        total_price: 'Total price',
                    },
                    filters: {
                        title: 'Filter',
                        title_filters: 'Filters',
                        clear_all: 'Clear all',
                        apply: 'Apply',
                        type_to_search: 'Type to search...',
                        sections_titles: {
                            transaction_id: 'Transaction ID',
                            product: 'Product',
                            status: 'Status',
                        },
                    },
                    total: '{{price, currency(USD)}}'
                }
            },
            plan_details: {
                plan_description_info: {
                    rat: 'Radio Access Technology (RAT)',
                    credential_type: 'Credential Type',
                    coverage_type: 'Coverage Type',
                    mcc_mnc: 'MCC MNC',
                    network_type: 'Network Type',
                    smsc: 'SMSC',
                    network_app_uuid: 'Network App UUID'
                },
                plan_price_info: {
                    overage: 'Overage',
                    sms_mt: 'SMS MT',
                    sms_mo: 'SMS MO'
                },
                description: 'Description',
                access: 'Access',
                set_fallback: 'Set as fallback',
                install: 'Install',
                coverage: 'Coverage',
                see_map: 'See Map',
                coverage_map: 'Coverage Map'
            },
            onechip_details: {
                nav: {
                    general_information: '',
                    euiccs_performance: 'eUICC Performance',
                    network_reports: 'Network Reports',
                    platform_feed: 'Platform Feed',
                    meerkat: 'Meerkat',
                    send_sms: 'Send SMS'
                },
                network_status: {
                    success: {
                        esim_activate: 'OneChip activation started',
                        esim_suspend: 'OneChip was suspended',
                        esim_enable: 'OneChip was enabled'
                    }
                },
                general_information: {
                    network_status: {
                        title: 'Device Network Status',
                        actions: {
                            deactivate: 'Deactivate',
                            activate: 'Activate',
                            suspend: 'Suspend',
                            enable: 'Enable'
                        }
                    },
                    installed_apps: {
                        title: 'Installed Apps',
                        list_fields: {
                            profile_id: 'Profile ID',
                            profile_status: 'Profile Status',
                            fallback: 'Fallback Attribute (Lock)',
                            iccid: 'ICCID',
                            imsi: 'IMSI',
                            msisdn: 'MSISDN'
                        },
                        actions: {
                            activate: 'Activate'
                        }
                    },
                    datacap: {
                        title: 'Data Cap',
                        warning:
                            'Please make sure to set the active operational profile as "fallback" before setting the Data Cap limit. Failure to do so may trigger unintended consequences, including data consumption with the backup or bootstrap profile which may be billed at a higher rate.',
                        data_cap_set: 'Data Cap set',
                        data_cap_used: 'Data Cap used',
                        fields: {
                            update_data_cap: {
                                placeholder: 'Update Data Cap'
                            }
                        },
                        actions: {
                            remove: 'Remove Data Cap',
                            update: 'Update'
                        }
                    },
                    euicc_contents: {
                        title: 'eUICC Contents'
                    },
                    info_card: {
                        labels: {
                            account: 'Account',
                            sku: 'SKU',
                            imei: 'IMEI',
                            eid: 'EID'
                        },
                        fields: {
                            device_group: {
                                label: 'Device Group'
                            },
                            device_name: {
                                label: 'Device Name'
                            }
                        },
                        actions: {
                            submit: 'Update Group + Name'
                        }
                    }
                },
                network_reports: {
                    title: 'Network Event History (CDR)',
                    description: 'All dates and times are in UTC.',
                    actions: {
                        download: 'Download'
                    },
                    fields: {
                        periodStart: {
                            label: 'From (UTC)',
                        },
                        periodEnd: {
                            label: 'To (UTC)',
                        }
                    },
                    message: {
                        success: 'Report download initiated',
                        error: 'An error occured'
                    }
                },
                platform_feed: {
                    table_headers: {
                        eid: 'Eid',
                        message: 'Message',
                        title: 'Title',
                        date: 'Date',
                    },
                    filter: {
                        title: 'Filter',
                        selected_title: 'Filters',
                        date_filter: 'All timestamps are in UTC.',
                        copy_to_clipboard: 'Copy to clipboard.',
                        clear_all: 'Clear All',
                        apply: 'Apply',
                        eid: 'EID',
                        imsi: 'IMSI',
                        network_app: 'Network App',
                        type: 'Type',
                        sub_type: 'SubType',
                    },
                    expanded_row_keys: {
                        active_app: 'Network App ',
                        type: 'Type ',
                        sub_type: 'Subtype ',
                        imsi: 'IMSI ',
                        iccid: 'ICCID ',
                        plan_uuid: 'Network App UUID',
                    },
                    warning_modal: {
                        warning_period_start: 'The maximum time frame for search results are 90 days. The search From date will be adjusted to To date - 90 days.',
                        warning_period_end: 'The maximum time frame for search results are 90 days. The search To date will be adjusted to From date + 90 days.'
                    },
                    error_modal: {
                        cancel: 'Cancel',
                        continue: 'Continue',
                        error: 'Error!',
                        error_message_first_row: 'You are searching for archived data. When archived data is returned it will not include data from the last 2 hours.',
                        error_message_second_row: 'For more recent data, make sure to only select data for 7 days back.',
                    }
                },
                performance: {
                    title: 'eUICC performance',
                    description: 'Changing the heartbeat interval to something less frequent will reduce data consumption, but it will also impact your app activation process where less frequency means longer processing cycles.',
                    actions: {
                        update: 'Update',
                        activate: 'Activate'
                    },
                    fields: {
                        interval: {
                            label: 'Heartbeat Interval:',
                            min: 'min',
                            options: {
                                minutes: '{{count}} minutes',
                                hours_one: '{{count}} hour',
                                hours_other: '{{count}} hours'
                            }
                        },
                    },
                    alert: {
                        last_platform_connection: 'Last Platform Connection: <strong>{{value}}</strong>',
                        next_platform_connection: 'Next Platform Connection: <strong>{{value}}</strong>',
                        compatibility_check_passed: 'eUICC Compatibility Check passed',
                        compatibility_check_pending: 'eSim compatibility check pending',
                        compatibility_check_failed: 'eSim compatibility check failed'
                    },
                    no_data: {
                        title: 'No performance',
                        description: {
                            default: 'This is where you will be able to see recent heartbeats.',
                            not_activated: 'You will see your App performance graph here, once it is activated.'
                        }
                    },
                    message: {
                        success: 'OneChip activated',
                        error: 'An error occured'
                    }
                },
                meerkat: {
                    title: 'Meerkat Gateway IP assignments for the device',
                    description: 'Below is a list of your devices that are able to use the APN “meerkat.teal” to access advanced IP address assignment functionality.<br/><br/> \
                    Assigning a public IP will allow for connections from outside your VPN if you have one configured. Please ensure that you are on a Meerkat compatible network plan that is associated with the same region as the public IP that you have assigned.'
                },
                send_sms: {
                    title: 'Send SMS',
                    actions: {
                        send: 'Send',
                        reset: 'Delete Draft'
                    },
                    fields: {
                        text: {
                            placeholder: 'Type here...',
                            validation: {
                                required: 'Please enter the text'
                            }
                        }
                    },
                    message: {
                        success: 'Sent sms',
                        error: 'An error occured'
                    }
                },
                app_install_progress: {
                    default: {
                        title: 'Explore our App Marketplace',
                        description: 'This is where you will be able to see progress on the Network App install.',
                        actions: {
                            install: 'Install New Network App'
                        },
                    },
                    queued: {
                        title: 'Queued',
                        description: 'Your Network App install is in queue and will be processed on the next heartbeat.',
                        actions: {
                            cancel: 'Cancel'
                        },
                        message: {
                            success: 'Terminated plan change',
                            error: 'An error occured'
                        }
                    },
                    installing: {
                        title: 'Installing',
                        description: 'Network App installation is in process. The APN on your device may reset during installation. This is resolved by adding the APN again.',
                        actions: {
                            cancel: 'Cancel'
                        },
                    },
                    activated: {
                        title: 'Success',
                        description:
                            'The Network App is installed and enabled.<br/> \
                            The installed App and its contents are shown to the right.',
                        actions: {
                            ok: 'Ok'
                        },
                    },
                    failed: {
                        title: 'Failed',
                        description: 'App installation failed. This could happen if the APN is reset during installation. \
                        It is resolved by adding back the APN during the installation, before it fails.',
                        actions: {
                            retry: 'Retry',
                            install: 'Install new app'
                        },
                        message: {
                            success: 'Restarted plan change',
                            error: 'An error occured'
                        }
                    },
                    canceled: {
                        title: 'Canceled',
                        description: 'App installation was canceled. Please try again or select a new plan.',
                        actions: {
                            retry: 'Retry',
                            install: 'Install new app'
                        },
                        message: {
                            success: 'Restarted plan change',
                            error: 'An error occured'
                        }
                    }
                }
            },
            onechip_cards: {
                table: {
                    headers: {
                        eid: 'eID',
                        active_app: 'Enabled App',
                        operational_imsi: 'Operational IMSI',
                        operational_iccid: 'Operational ICCID',
                        bootstrap_imsi: 'Bootstrap IMSI',
                        bootstrap_iccid: 'Bootstrap ICCID',
                        device_group: 'Group',
                        device_name: 'Name',
                        account: 'Account',
                        network_status: 'Device Network Status',
                        operation_status: 'Operation Status',
                        last_esim_hearbeat: 'Last eSIM Heartbeat',
                        private_bootstrap_ip: 'Private Bootstrap IP',
                        private_operational_ip: 'Private Operational IP',
                        public_bootstrap_ip: 'Public Bootstrap IP',
                        public_operational_ip: 'Public Operational IP',
                        total_usage: 'Usage'
                    }
                },
                filters: {
                    title: 'Filter',
                    clear_all: 'Clear all',
                    sections_titles: {
                        account: 'Account',
                        eid: 'eID',
                        operational_imsi: 'Operational IMSI',
                        bootstrap_imsi: 'Bootstrap  IMSI',
                        operational_iccid: 'Operational ICCID',
                        bootstrap_iccid: 'Bootstrap  ICCID',
                        device_group: 'Device group',
                        device_name: 'Device name',
                        network_app: 'Network App',
                        network_status: 'Device Network Status',
                        operation_status: 'eSIM Operation Status',
                        meerkat: 'Meerkat',
                        network_app_filter: 'No network apps found',
                    },
                    network_status: {
                        online: 'Online',
                        waiting: 'Activated',
                        stopped: 'Suspended',
                        deactivated: 'Deactivated',
                        unactivated: 'Unactivated',
                        activating: 'Activating',
                        deactivating: 'Deactivating'
                    },
                    operation_status: {
                        failed: 'Failed',
                        activated: 'Success',
                        installing: 'Installing',
                        queued: 'Queued',
                        canceled: 'Canceled'
                    },
                    meerkat: {
                        assigned: 'Assigned IPs',
                        unassigned: 'Unassigned IPs'
                    }
                },
                actions: {
                    label: 'Actions',
                    general_message: 'It looks like this is taking longer than expected to process. Thank you for your patience.',
                    all_selected_message: 'All eIDs selected will be included in the report.',
                    activate: {
                        label: 'Activate',
                        title: 'Activate OneChips?',
                        actions: {
                            cancel: 'Cancel',
                            activate: 'Activate'
                        },
                        description: 'Do you want to activate all selected eIDs?',
                        message: {
                            success: 'Activation started successfully for {{processedCount}} out of {{totalCount}} OneChips'
                        }
                    },
                    deactivate: {
                        label: 'Deactivate',
                        title: 'Deactivate OneChips?',
                        returns_credentials_message: 'This returns credentials to the pool and its permanent. A deactivated OneChip can not be reactivated.\n',
                        forward_request: 'To move forward with your request, please send an email to Support including a list of all eIDs you want to deactivate.',
                        actions: {
                            ok: 'Ok',
                        },
                    },
                    suspend: {
                        label: 'Suspend',
                        title: 'Suspend OneChips?',
                        description: 'All eIDs selected by the filter will be blocked from consuming data.',
                        actions: {
                            cancel: 'Cancel',
                            suspend: 'Suspend',
                        },
                        message: {
                            success: '{{val}} OneChips suspended'
                        }
                    },
                    enable: {
                        label: 'Enable',
                        title: 'Enable OneChips?',
                        description: 'All eIDs selected by the filter will be allowed to consume data.',
                        actions: {
                            cancel: 'Cancel',
                            enable: 'Enable',
                        },
                        message: {
                            success: '{{val}} OneChips enabled'
                        }
                    },
                    updateDeviceGroupName: {
                        label: 'Update Device Group Name',
                        title: 'Update device group name?',
                        description: 'All eIDs selected by the filter will be assigned the device group name you enter.',
                        fields: {
                            text: {
                                label: 'Device Group Name'
                            }
                        },
                        actions: {
                            cancel: 'Cancel',
                            update: 'Update',
                        },
                        message: {
                            success: '{{val}} Device group names updated'
                        }
                    },
                    datacap: {
                        label: 'Data Cap',
                        setdatacap: {
                            label: 'Set',
                            title: 'Set data cap',
                            description: 'Set a monthly data cap on the selected eSIMs. Data consumption will be suspended after the selected amount of data been consumed. Data consumption will be resumed for any suspended card at the start of the next billing cycle.',
                            message: {
                                success: 'Data Cap set'
                            }
                        },
                        removedatacap: {
                            label: 'Remove',
                            title: 'Remove data cap',
                            message: {
                                success: 'Data Cap removed'
                            }
                        },
                    },
                    bulk_upload: {
                        label: 'Bulk Upload (CSV)',
                        select_file: 'Select File',
                        drag_drop: 'Drag & drop your CSV files here',
                        or: '',
                        download_example: 'Download example file',
                        bulk_activate: {
                            label: 'Bulk activate',
                            title: 'Bulk activate',
                            description: '  Select a CSV file with header “eid” followed by eIDs listed in one column. All eIDs defined in the file will be activated.',
                            message: {
                                success: 'Activation started successfully for {{processedCount}} out of {{totalCount}} OneChips'
                            }
                        },
                        bulk_edit: {
                            label: 'Bulk edit',
                            title: 'Bulk edit',
                            description: 'Editable fields are Network App, Device Group Name, Device Name, Data Cap, Enable/Disable. List eIDs in the left \
                            most column, under header “eID”. Headers for editable values should follow to the right.',
                            warning: 'Device group, device name and cap left blank will DELETE any previous values, e.g. leaving cap blank will remove cap completely. You may download the OneChip eUICCs report from the Actions menu to base the Bulk Edit file on if you want to maintain current values.',
                            message: {
                                success: 'The CSV file has been processed.'
                            }
                        },
                        bulk_plan_change: {
                            label: 'Bulk plan change',
                            title: 'Bulk plan change',
                            bulk_plan_change_csv: 'Bulk Plan Change (CSV)',
                            select_file_general: 'Select a CSV file with header “eid” followed by eIDs listed in one column. All eIDs defined in the file will be activated.',
                            message: {
                                success: '{{val}} OneChips activated'
                            },
                            continue: 'Continue'
                        },
                    },
                    send_sms: {
                        label: 'Send SMS',
                        title: 'Send SMS',
                        message: {
                            success: 'Sent sms'
                        }
                    },
                    download_reports: {
                        label: 'Download reports',
                        download_onechips_report: {
                            label: 'OneChip eUICCs',
                            title: 'Download OneChip eUICC report',
                            message: {
                                success: 'Downloaded OneChips report'
                            }
                        },
                        download_usage_report: {
                            label: 'Usage',
                            title: 'Download usage report',
                            message: {
                                success: 'Downloaded usage report'
                            },
                        },
                        download_network_history: {
                            label: 'Network history events (CDRs)',
                            title: 'Download network history (CDR) report',
                            message: {
                                success: 'Downloaded network history report'
                            }
                        },
                        download_plan_report: {
                            label: 'Network Apps',
                            title: 'Download network apps report',
                            message: {
                                success: 'Downloaded network apps report'
                            },
                            warning: 'You are currently viewing all accounts. Please select the specific account you want to download the available network apps for, using the account filter at the top right corner. Then proceed to download the report.'
                        },
                    },
                    meerkat: {
                        label: 'Meerkat',
                        assign_private: {
                            label: 'Assign Private IP',
                            details: 'VPN setup fee: {{price, currency(USD)}}',
                            description: 'Please contact <Link to=mailto:{{email}}>{{email}}</Link> or your account manager to order the Meerkat VPN Gateway and get your VPN integration set up.',
                            assign_operational: {
                                label: 'Operational',
                                title: 'Assign Private IP?',
                                message: {
                                    success: '{{val}} IPs assigned'
                                }
                            },
                            assign_bootstrap: {
                                label: 'Bootstrap',
                                title: 'Assign Private IP?',
                                message: {
                                    success: '{{val}} IPs assigned'
                                }
                            },
                        },
                        assign_public: {
                            label: 'Assign Public IP',
                            details: '{{price, currency(USD)}}/month per IP address',
                            description: 'Public IPs will be invoiced at the end of the monthly billing cycle.',
                            assign_operational: {
                                label: 'Operational',
                                title: 'Assign IP?',
                                message: {
                                    success: '{{val}} IPs assigned'
                                }
                            },
                            assign_bootstrap: {
                                label: 'Bootstrap',
                                title: 'Assign IP?',
                                message: {
                                    success: '{{val}} IPs assigned'
                                }
                            },
                        },
                        remove_ip: {
                            label: 'Remove IP',
                            description: 'Do you want to continue removing the IP?',
                            remove_operational: {
                                label: 'Operational',
                                title: 'Remove IP?',
                                message: {
                                    success: '{{val}} IPs removed'
                                }
                            },
                            remove_bootstrap: {
                                label: 'Bootstrap',
                                title: 'Remove IP?',
                                message: {
                                    success: '{{val}} IPs removed'
                                }
                            },
                        }
                    }
                }
            }
        }
    }
};

export default en;
